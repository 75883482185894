<template>
  <div>
    <pricing-rules-list />
    <pricing-tiers-list />
    <discounts-list />
    <span class="text-left font-medium-5 text-body font-weight-bolder mb-2">
      {{ $t('Costing Defaults') }}
    </span>
    <validation-observer
      ref="catalogPricingVal"
    >
      <b-row>
        <b-col
          md="6"
        >
          <component
            :is="costingDefault['catalog_price_costing_default'].type"
            :key="'catalog_price_costing_default'"
            v-model="catalogPricing['catalog_price_costing_default']"
            v-bind="getProps('catalog_price_costing_default')"
          />
        </b-col>
      </b-row>
      <portal to="body-footer">
        <div class="d-flex mt-2 pb-1 justify-content-between">
          <div>
            <!--            <b-button-->
            <!--              v-if="!isFormChanged"-->
            <!--              class="cancelBtn font-medium-1 font-weight-bolder"-->
            <!--              variant="outline-primary"-->
            <!--              @click="$router.push({ name: '' })"-->
            <!--            >-->
            <!--              {{ $t('Back to List') }}-->
            <!--            </b-button>-->
          </div>
          <div>
            <b-button
              variant="success"
              class="saveBtn font-medium-1 font-weight-bolder"
              :disabled="!isFormChanged"
              @click="submit"
            >
              {{ $t('SAVE') }}
            </b-button>
          </div>
        </div>
      </portal>
    </validation-observer>
  </div>
</template>

<script>
import PricingRulesList from '@/views/settings/catalog/pricing/list/PricingRulesList.vue'
import PricingTiersList from '@/views/settings/catalog/pricing/list/PricingTiersList.vue'
import DiscountsList from '@/views/settings/catalog/pricing/list/DiscountsList.vue'
import tableConfig from '@/views/settings/catalog/pricing/pricingConfig'
import { BButton, BCol, BRow } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'Pricing',
  components: {
    BButton,
    BCol,
    BRow,
    DiscountsList,
    PricingRulesList,
    PricingTiersList,
    ValidationObserver,
  },
  computed: {
    catalogPricing() {
      return this.$store.state[this.CATALOG_PRICE_MODULE].catalogPrice
    },
    catalogPricingClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingCatalogPricingClone
    },
    isFormChanged() {
      return JSON.stringify(this.catalogPricing) !== this.catalogPricingClone
    },
  },
  created() {
    this.$store.dispatch(`${this.CATALOG_PRICE_MODULE}/getCatalogPricing`).then(res => {
      const { data } = res.data
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_CATALOG_PRICING`, data)
    })
    this.$emit('updateMenu', 'settings-catalog-pricing-list')
  },
  methods: {
    submit() {
      this.$refs.catalogPricingVal.validate()
      this.sendNotification(this, { catalog_price_costing_default: this.catalogPricing.catalog_price_costing_default }, `${this.CATALOG_PRICE_MODULE}/updateCatalogPricing`)
        .then(res => {
          const { data } = res.data
          this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_CATALOG_PRICING`, data)
          // this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id)
          // this.$router.replace({
          //   name: 'home-customers-view',
          //   params: { id: res.data.data?.id },
          // }).catch(() => { })
        })
    },
    getProps(fieldName) {
      return {
        is: this.costingDefault[fieldName].type,
        field: this.costingDefault[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const CATALOG_PRICE_MODULE = 'catalog-price'
    const MODULE_NAME_CLONE = 'cloneData'
    const {
      costingDefault,
    } = tableConfig()
    return {
      costingDefault,
      MODULE_NAME_CLONE,
      CATALOG_PRICE_MODULE,
    }
  },
}
</script>
