var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('h1',{staticClass:"text-left font-medium-5 text-body font-weight-bolder mb-2"},[_vm._v(" "+_vm._s(_vm.$t("Discounts"))+" ")]),_c('span',[_c('feather-icon',{staticClass:"mr-1 align-items-center",attrs:{"icon":"LTipNoteIcon","title":_vm.$t('Set Inactive'),"size":"16"}}),_vm._v(" "+_vm._s(_vm.$t( "If 2 discounts apply to the same item, the system will apply the discount with the highest priority. " ))+" ")],1),_c('l-draggable-table',{ref:"lTableRef",attrs:{"table-config-options":_vm.getTableConfigOptions(),"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumnsDiscounts,"create-page-url":{ name: 'settings-catalog-discount-create' },"trash-list-path":"settings-catalog-discounts-trash-list","is-pagination-able":false,"is-searchable":false},on:{"onChoose":_vm.onChoose},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-content-start"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"mr-1 cursor-pointer",attrs:{"icon":"LTrashIconKits","title":_vm.$t('Set Inactive'),"size":"32"},on:{"click":function($event){return _vm.remove(data)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"mr-1 cursor-pointer",attrs:{"icon":"LCopyIconKits","size":"32","title":_vm.$t('Duplicate')},on:{"click":function($event){return _vm.duplicate(data)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"cursor-pointer",attrs:{"title":_vm.$t('Update'),"icon":"LPenIconUpdated","size":"32"},on:{"click":function($event){return _vm.gotoNewPage(
              {
                name: 'settings-catalog-discounts-update',
                params: { id: data.id },
              },
              $event
            )}}})],1)]}},{key:"cell(time_period)",fn:function(data){return [(data.data)?_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(data.data.start ? _vm.formatYMD(data.data.start) : "No time"))]),_c('span',[_vm._v(" - ")]),_c('span',[_vm._v(_vm._s(data.data.end ? _vm.formatYMD(data.data.end) : "No time"))])]):_vm._e()]}},{key:"cell(rate)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.data.rate)),_c('span',[_vm._v("%")])])]}},{key:"cell(position)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.data.position)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }