<template>
  <div class="pb-2">
    <h1 class="text-left font-medium-5 text-body font-weight-bolder mb-2">
      {{ $t("Discounts") }}
    </h1>
    <span>
      <feather-icon
        icon="LTipNoteIcon"
        :title="$t('Set Inactive')"
        size="16"
        class="mr-1 align-items-center"
      />
      {{
        $t(
          "If 2 discounts apply to the same item, the system will apply the discount with the highest priority. "
        )
      }}
    </span>
    <l-draggable-table
      ref="lTableRef"
      :table-config-options="getTableConfigOptions()"
      :module-name="MODULE_NAME"
      :table-columns="tableColumnsDiscounts"
      :create-page-url="{ name: 'settings-catalog-discount-create' }"
      trash-list-path="settings-catalog-discounts-trash-list"
      :is-pagination-able="false"
      :is-searchable="false"
      @onChoose="onChoose"
    >
      <template #cell(actions)="{ data }">
        <div class="d-flex justify-content-start">
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            icon="LTrashIconKits"
            class="mr-1 cursor-pointer"
            :title="$t('Set Inactive')"
            size="32"
            @click="remove(data)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            icon="LCopyIconKits"
            size="32"
            class="mr-1 cursor-pointer"
            :title="$t('Duplicate')"
            @click="duplicate(data)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Update')"
            icon="LPenIconUpdated"
            size="32"
            class="cursor-pointer"
            @click="
              gotoNewPage(
                {
                  name: 'settings-catalog-discounts-update',
                  params: { id: data.id },
                },
                $event
              )
            "
          />
        </div>
      </template>
      <template #cell(time_period)="data">
        <div
          v-if="data.data"
          class="text-center"
        >
          <span>{{
            data.data.start ? formatYMD(data.data.start) : "No time"
          }}</span>
          <span> - </span>
          <span>{{
            data.data.end ? formatYMD(data.data.end) : "No time"
          }}</span>
        </div>
      </template>
      <template #cell(rate)="data">
        <div class="text-center">
          {{ data.data.rate }}<span>%</span>
        </div>
      </template>
      <template #cell(position)="data">
        <div class="text-center">
          {{ data.data.position }}
        </div>
      </template>
    </l-draggable-table>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { title } from '@core/utils/filter'
import { VBTooltip } from 'bootstrap-vue'
import LDraggableTable from '@/views/components/LDraggableTable.vue'
import { parseYMD } from '@core/components/b-table-responsive/table-responsive/table/utils/date'
import tableConfig from '../pricingConfig'

export default {
  name: 'DiscountsList',
  components: {
    LDraggableTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selected: '',
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
      draggedItem: null,
    }
  },
  created() {
    this.$emit('updateMenu', 'settings-catalog-pricing-rules-list')
  },
  methods: {
    formatYMD(date) {
      date = parseYMD(date)
      if (!date) {
        return null
      }
      const year = `${date.getFullYear()}`.slice(-2)
      const month = `0${date.getMonth() + 1}`.slice(-2)
      const day = `0${date.getDate()}`.slice(-2)
      return `${month}/${day}/${year}`
    },
    refetchData() {
      this.$refs.lTableRef.fetchTableItemsList()
    },
    remove(data) {
      const { id } = data
      this.confirmNotification(this, [id], `${this.MODULE_NAME}/moveToTrash`, {
        text: 'It is possible to revert this',
        confirmButtonText: 'Yes, deactivate it',
      }).then(() => {
        this.refetchData()
      })
    },
    onChoose(item) {
      const {
        moved: {
          element: { id },
          newIndex,
        },
      } = item

      this.sendNotification(
        this,
        { discount: id, position: newIndex },
        `${this.MODULE_NAME}/setPosition`,
      )
        .then(() => {
          this.refetchData()
          this.isLoadingOfSetDisabled = false
        })
        .catch(err => {
          this.$toast.error(err.response.data.message)
        })
    },
    // setDefault(data) {
    //   console.log(data)
    //   const { id } = data
    //   this.isLoadingOfSetDisabled = true
    //   this.idOfSettledDefault = id
    //   this.sendNotification(
    //     this,
    //     {
    //       id,
    //     },
    //     `${this.MODULE_NAME}/setDefault`,
    //   ).then(() => {
    //     this.refetchData()
    //     this.isLoadingOfSetDisabled = false
    //   })
    // },
    update(data) {
      this.$router.push({
        name: 'settings-catalog-discounts-update',
        params: { id: data.id },
      })
    },
    duplicate(data) {
      this.$refs.lTableRef.isBusy = true
      this.$store
        .dispatch(`${this.MODULE_NAME}/duplicate`, data)
        .then(() => {
          this.refetchData()
        })
        .catch(() => {
          this.$refs.lTableRef.isBusy = false
        })
    },
    getTableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/list`,
        data: {
          is_active: 1,
        },
      }
    },
  },
  setup() {
    const MODULE_NAME = 'discounts'
    const { tableColumnsDiscounts } = tableConfig()
    return {
      tableColumnsDiscounts,
      MODULE_NAME,
      title,
    }
  },
}
</script>
